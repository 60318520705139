import React, { useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonPage, IonContent } from '@ionic/react';
import { useSubscription } from '@apollo/react-hooks';
import { batchDetailsSubscription } from '../queries/batches';
import { BatchDetailsSubscription } from '../generated/graphql';
import BatchHistoryTable from '../components/BatchHistoryTable';
import BatchHistoryChart from '../components/BatchHistoryChart';
import BatchDetailsList from '../components/BatchDetails';
import BatchNotes from '../components/BatchNotes';

interface BatchDetailsProps extends RouteComponentProps<{
    id: string;
}>{}
const BatchDetails:React.FC<BatchDetailsProps> = ({match}) => {
    const variables = {
        id: match.params.id
    };
    const {data, loading, error} = useSubscription<BatchDetailsSubscription>(batchDetailsSubscription, { variables });
    
    if (error) console.log(error);
    return <IonPage>
        <IonContent>
            {data && <div>
                <BatchDetailsList data={data}></BatchDetailsList>
                <BatchHistoryChart batchData={data}></BatchHistoryChart>
                <BatchHistoryTable data={data} batchStart={data.batch_by_pk?.started}></BatchHistoryTable>
                <BatchNotes batchData={data}></BatchNotes>
            </div>}
        </IonContent>
    </IonPage>
}

export default BatchDetails;
import React, {useRef, useEffect, useState} from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { BatchDetailsSubscription } from '../generated/graphql';
import { differenceInSeconds } from 'date-fns';
import { formatDuration } from '../shared/utils/dates';

interface BatchHistoryTableProps {
    batchStart: Date;
    data: BatchDetailsSubscription;
}
const BatchHistoryTable: React.FC<BatchHistoryTableProps> = ({ data, batchStart }) => {
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   

    const tableRef = useRef(null)
    useEffect(() => {
        const div = document.getElementById("table");
        div.scrollTop = 0-div.scrollHeight;
        // tableRef.current.scrollTop = 0
    }, [data])


    return (
    <>
    <IonRow style={{ "fontWeight": "bold" }}>
            <IonCol>Time mm:ss</IonCol>
            <IonCol>Product °C</IonCol>
            <IonCol>Air °C</IonCol>
            <IonCol>Stage</IonCol>
        </IonRow>
    <div ref={tableRef} id="table" className="ion-padding" style={{display: 'flex', flexDirection: 'column-reverse', height: 100, overflow: 'scroll'}}>
        {data.batch_by_pk?.sensor_readings.map((sensor_reading, i) => {

            const formattedDuration = formatDuration(new Date(sensor_reading.timestamp), new Date(batchStart), 'm');
            // setLast({
            //     time: formattedDuration,
            //     product: sensor_reading.product_temp,
            //     air: sensor_reading.air_temp,
            //     stage: sensor_reading.stage
            // })
    
            
            return (
                <div className="batch-grid" style={{display: 'flex',flexDirection: 'row'}}>
                    <IonCol>{formattedDuration}</IonCol>
                    <IonCol>{sensor_reading.product_temp}</IonCol>
                    <IonCol>{sensor_reading.air_temp}</IonCol>
                    <IonCol>{sensor_reading.stage}</IonCol>
                </div>
            )
         
        })}
    </div>
        </>
    )
}

export default BatchHistoryTable;
import React from 'react';
import ReactDOM from 'react-dom';
import Auth from './shared/components/Auth';
import App from './App';
import RollbarContext from './shared/context/RollbarContext';
import Rollbar from 'rollbar';

const rollbar = new Rollbar({
    accessToken: "f4b91a5a55cb48ec872572cabe55e275",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: "dev",
        // person: {
        //     id: user.sub,
        //     username: user.nickname,
        //     email: user.email
        // }
    }
});

const renderApp = (props:any) => {
    ReactDOM.render(
        <RollbarContext.Provider value={rollbar}>
            <Auth role={"farmer"}>
                <App/>
            </Auth>
        </RollbarContext.Provider>
    , document.getElementById('root'));
}
renderApp({});